<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoNivel"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="id_niveis"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Níveis</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <RegisterBottom
            v-can-access="382"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.niveis`]="{ item }">
        {{ item.niveis | TitleCase }}
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn icon v-can-access="383" @click="editItem(item)" color="orange" dark>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-can-access="384"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom :name="'edit'" v-can-access="383" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="384"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadNivel
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editNivel="nivel"
      @close="refresh()"
    />
  </div>
</template>

<script>
import sistema from "@/services/http/sistemaService";
import BaseTable from "@/components/shared/NewBaseTable";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalCadNivel from "@/components/sistema/niveis/ModalCadNivel";
import IconBottom from "@/components/shared/bottons/IconBottom";

export default {
  name: "ListaMenus",

  components: {
    BaseTable,
    RegisterBottom,
    ModalCadNivel,
    IconBottom
  },

  data() {
    return {
      loading: false,
      dialog: false,
      labelBtn: "Salvar",
      search: "",
      infoNivel: [],
      nivel: {},
      headers: [
        { text: "ID", value: "id_niveis" },
        { text: "Nível", value: "niveis" },
        { text: "Ações", value: "acoes", align: "center" }
      ]
    };
  },

  methods: {
    async fetchNiveis() {
      this.loading = true;
      let { data } = await sistema()
        .niveis()
        .show({
          per_page: -1
        });
      this.infoNivel = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.nivel = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deletar nível",
          `Deseja deletar o nível ${item.niveis}?`
        );
        sistema()
          .niveis(item.id_niveis)
          .delete({}, { notification: true, message: "Nível deletado" });
      } catch (error) {
        console.log(error);
      }
    },

    refresh() {
      this.dialog = false;
      this.fetchNiveis();
    }
  },

  mounted() {
    this.fetchNiveis();
  }
};
</script>
