const inputRules = {
  data() {
    return {
      rules: {
        required: v => !!v || "Campo não pode ser vazio",
        email: v => /.+@.+\..+/.test(v) || "E-mail deve ser válido",
        emailCometa: v =>
          /^[a-z](\.?[a-z0-9]){2,}@viacometa.com.br/.test(v) ||
          "E-mail deve ser do domínio @viacometa.com.br",
        numeric: v => (v && !isNaN(v)) || "Campo deve ser numérico",
        currency: v => (v && v) || "Campo inválido",
        password: v =>
          /^[A-Za-z\S]{8,}$/.test(v) ||
          "A senha deve conter no mínimo 8 caracteres",
        Max200CaracterLimit: v =>
          v.length <= 200 || "Limite máximo de caracteres atingido.",
        maxLength: limit => v =>
          (v || "").length <= limit ||
          `O limite máximo de caracteres é ${limit}`,
        maxValue: limit => v =>
          (v || "") <= limit || `O valor deve ser menor que ${limit}`,
        diffentThan: (value, label) => v =>
          !(v === value) || `O valor não pode ser o mesmo de que ${label}`
      }
    };
  }
};

export default inputRules;
