<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoPapel"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="id"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Papéis</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <RegisterBottom
            v-can-access="402"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <!--<v-btn icon v-can-access="403" @click="editItem(item)" color="orange" dark>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-can-access="404"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom :name="'edit'" v-can-access="403" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="404"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadPapel
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editPapel="papel"
      @close="refresh()"
    />
  </div>
</template>

<script>
import sistema from "@/services/http/sistemaService";
import BaseTable from "@/components/shared/NewBaseTable";
import IconBottom from "@/components/shared/bottons/IconBottom";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalCadPapel from "@/components/sistema/papeis/ModalCadPapel";

export default {
  name: "ListaPapel",

  components: {
    BaseTable,
    RegisterBottom,
    ModalCadPapel,
    IconBottom
  },

  data() {
    return {
      loading: false,
      dialog: false,
      labelBtn: "Salvar",
      search: "",
      infoPapel: [],
      papel: {},
      headers: [
        { text: "ID", value: "id" },
        { text: "Papel", value: "nome" },
        { text: "Descrição", value: "descricao" },
        { text: "Ações", value: "acoes", align: "center" }
      ]
    };
  },

  methods: {
    async fetchPapel() {
      this.loading = true;
      let { data } = await sistema()
        .papeis()
        .show(
          {
            per_page: -1
          },
          { notification: false },
          { useCache: false }
        );
      this.infoPapel = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.papel = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Isso não pode ser desfeito",
          `Deseja deletar o papel ${item.nome}?`
        );
        sistema()
          .papeis(item.id)
          .delete({}, { notification: true, message: "Papel deletado" });
      } catch (error) {
        console.log(error);
      }
      await this.fetchPapel();
    },

    async refresh() {
      this.dialog = false;
      await this.fetchPapel();
    }
  },

  mounted() {
    this.fetchPapel();
  }
};
</script>
